import React, { useEffect, useState } from 'react'
import {
  Box, CircularProgress,
} from '@mui/material'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import ErrorLoading from '../../components/layout/ErrorLoading'
import TemplateBuilder from '../../components/whatsapp/TemplateBuilder'

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5000/api' : 'https://sokolink-api.herokuapp.com/api'

function EditTemplate({ firebaseUser }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  const { templateName } = useParams()

  useEffect(async () => {
    try {
      setLoading(true)
      const token = await firebaseUser?.getIdToken()
      // Send GET request to the server
      const response = await fetch(`${baseUrl}/waba/templates/${templateName}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          AuthToken: `${token}`,
        },
      })
      if (!response.ok) {
        setLoading(false)
        setError(true)
      }
      const res = await response.json()
      setData(res.data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setError(e)
    }
  }, [])

  if (loading) {
    return (
      <Box sx={{
        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', my: 3,
      }}
      >
        <CircularProgress size={20} />
      </Box>
    )
  }

  if (error) return (<div><ErrorLoading visible reload={() => { window.location.reload() }} /></div>)

  return (
    <TemplateBuilder template={data?.template} config={data?.config} />
  )
}

const mapStateToProps = (state) => ({
  firebaseUser: state.user.user,
})
export default connect(mapStateToProps, { })(EditTemplate)
