import { gql } from '@apollo/client'

export const ADD_WHATSAPP_CAMPAIGN = gql`
mutation AddCampaign(
    $name: String!
    $templateName: String!
    $storeId: ID
    $targetCustomers: String!
    $customerLimit: Int
    $customerOffset: Int
    $sortField: String
    $sortOrder: String
    $bodyParams: JSON!
    $headerImageUrl: String
  ) {
    createWhatsappCampaign(
      name: $name
      templateName: $templateName
      storeId: $storeId
      targetCustomers: $targetCustomers
      customerLimit: $customerLimit
      customerOffset: $customerOffset
      sortField: $sortField
      sortOrder: $sortOrder
      bodyParams: $bodyParams
      headerImageUrl: $headerImageUrl
    ) {
      id
      merchantId
      storeId
      name
      templateName
      bodyParams
      headerImageUrl
      targetCustomers
      customerLimit
      customerOffset
      sortField
      sortOrder
      status
      reach
      sentOn
      createdOn
    }
  }
`

export const EDIT_WHATSAPP_CAMPAIGN = gql`
mutation EditCampaign(
  $id: ID!
  $name: String!
  $templateName: String!
  $storeId: ID
  $targetCustomers: String!
  $customerLimit: Int
  $customerOffset: Int
  $sortField: String
  $sortOrder: String
  $bodyParams: JSON!
  $headerImageUrl: String
) {
  updateWhatsappCampaign(
    id: $id
    name: $name
    templateName: $templateName
    storeId: $storeId
    targetCustomers: $targetCustomers
    customerLimit: $customerLimit
    customerOffset: $customerOffset
    sortField: $sortField
    sortOrder: $sortOrder
    bodyParams: $bodyParams
    headerImageUrl: $headerImageUrl
  ) {
    id
    merchantId
    storeId
    name
    templateName
    bodyParams
    headerImageUrl
    targetCustomers
    customerLimit
    customerOffset
    sortField
    sortOrder
    status
    reach
    sentOn
    createdOn
  }
}
`

export const WHATSAPP_CAMPAIGNS = gql`
query WhatsappCampaigns(
    $status: String
    $limit: Int!
    $offset: Int!
    $name: String
  ) {
    whatsappCampaigns(
      status: $status
      limit: $limit
      offset: $offset
      name: $name
    ) {
      id
      merchantId
      storeId
      name
      templateName
      bodyParams
      headerImageUrl
      targetCustomers
      customerLimit
      customerOffset
      sortField
      sortOrder
      status
      reach
      sentOn
      createdOn
    }
  }  
`

export const BROADCAST_WHATSAPP_CAMPAIGN = gql`
mutation BroadcastCampaign(
  $id: ID!
) {
  broadcastWhatsappCampaign(
    id: $id
  ) {
    id
    merchantId
    storeId
    name
    templateName
    bodyParams
    headerImageUrl
    targetCustomers
    customerLimit
    customerOffset
    sortField
    sortOrder
    status
    reach
    sentOn
    createdOn
  }
}
`
