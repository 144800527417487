import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../../services/firebase'
import ResponsiveModal from '../layout/ResponsiveModal'
import BtnLoader from '../spinners/BtnLoader'
import OverviewInput from '../inputs/OverviewInput'

export default function ResetModal({ visible, close }) {
  if (!visible) { return null }
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      await sendPasswordResetEmail(auth, email)
      toast.success('Password reset link sent, check your inbox.')
      setLoading(false)
    } catch (error) {
      const errorMessage = error.message
      toast.error(errorMessage)
      setLoading(false)
    }
  }

  return (
    <>
      <ResponsiveModal visible title="Reset Password" cancellable cancel={close}>
        <form className="modal-form" onSubmit={handleSubmit}>
          <OverviewInput type="email" required label="Email" placeholder="your email" value={email} changeHandler={(e) => setEmail(e.target.value)} />
          <button type="submit" disabled={loading} className="auth-submit">
            {!loading && <h5>Send Link</h5>}
            <BtnLoader visible={loading} color="#fff" />
          </button>
        </form>
      </ResponsiveModal>
    </>
  )
}
