/* eslint-disable no-empty */
import React, { useEffect, useState } from 'react'
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { Provider } from 'react-redux'
import { Toaster } from 'react-hot-toast'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import './index.css'
import { auth } from './services/firebase'
import store from './store'
import client from './apollo'
import Login from './pages/Login'
import PrivateRoute from './context/PrivateRoute'
import Register from './pages/Register'
import RoutePlanner from './context/RoutePlanner'
// business
import BusinessSettings from './pages/Settings'
import BusinessLocations from './pages/Locations'
import Users from './pages/Users'
import Payments from './pages/Payments'
import Settlements from './pages/Settlements'
import BusinessWallet from './pages/Wallet'
import BusinessProducts from './pages/Products'
import BusinessOrders from './pages/Orders'
import CustomersPage from './pages/Customers'
import Home from './pages/Home'
import Account from './pages/Account'
import AppLoading from './components/spinners/AppLoading'
import CouponsPage from './pages/Coupons'
import Catalogues from './pages/Catalogues'
import Storefront from './pages/Storefront'
import MallHome from './pages/mall/overview.page'
import FloorMap from './pages/mall/floormap.page'
import MallStores from './pages/mall/mallstores.page'
import EditTemplate from './pages/whatsapp/EditTemplate'
import CreateTemplate from './pages/whatsapp/CreateTemplate'
import WhatsappCampaignsPage from './pages/whatsapp/Campaigns'
import WhatsappTemplatesPage from './pages/whatsapp/Templates'
import SmsCampaignsPage from './pages/Sms'
import CustomersExperience from './pages/CustomersExperience'
import LoyaltyProgramPage from './pages/LoyaltyProgram'
import WhatsappAutomationPage from './pages/whatsapp/Automation'

export default function App() {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    try {
      auth.onAuthStateChanged((user) => {
        if (user) {
          store.dispatch({ type: 'AUTHENTICATE_USER', payload: user })
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
    } catch (error) {
    }
  }, [])
  if (loading) {
    return <AppLoading />
  }
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className="App">
              <Toaster />

              <Switch>
                <Route exact path="/register" component={Register} />
                <Route exact path="/login" component={Login} />

                {/* Business Routes */}
                <PrivateRoute exact path="/" component={RoutePlanner} />
                <PrivateRoute exact path="/business/overview" component={Home} />
                <PrivateRoute exact path="/business/stores" component={BusinessLocations} />
                <PrivateRoute exact path="/business/storefront" component={Storefront} />
                <PrivateRoute exact path="/business/catalogues" component={Catalogues} />
                <PrivateRoute exact path="/business/catalogues/:catalogueId" component={BusinessProducts} />
                <PrivateRoute exact path="/business/products" component={BusinessProducts} />
                <PrivateRoute exact path="/business/orders" component={BusinessOrders} />
                <PrivateRoute exact path="/business/customers/records" component={CustomersPage} />
                <PrivateRoute exact path="/business/customers/experience" component={CustomersExperience} />
                <PrivateRoute exact path="/business/customers/loyalty" component={LoyaltyProgramPage} />
                <PrivateRoute exact path="/business/coupons" component={CouponsPage} />
                <PrivateRoute exact path="/business/whatsapp/templates/create" component={CreateTemplate} />
                <PrivateRoute exact path="/business/whatsapp/automation" component={WhatsappAutomationPage} />
                <PrivateRoute exact path="/business/whatsapp/campaigns" component={WhatsappCampaignsPage} />
                <PrivateRoute exact path="/business/whatsapp/templates" component={WhatsappTemplatesPage} />
                <PrivateRoute exact path="/business/whatsapp/templates/:templateName" component={EditTemplate} />
                <PrivateRoute exact path="/business/sms" component={SmsCampaignsPage} />
                <PrivateRoute exact path="/business/users" component={Users} />
                <PrivateRoute exact path="/business/payments" component={Payments} />
                <PrivateRoute exact path="/business/settlements" component={Settlements} />
                <PrivateRoute exact path="/business/wallet" component={BusinessWallet} />
                <PrivateRoute exact path="/business/account" component={Account} />
                <PrivateRoute exact path="/business/settings" component={BusinessSettings} />

                {/* Mall Routes */}
                <PrivateRoute exact path="/mall/overview" component={MallHome} />
                <PrivateRoute exact path="/mall/floor" component={FloorMap} />
                <PrivateRoute exact path="/mall/businesses" component={MallStores} />

              </Switch>
            </div>
          </LocalizationProvider>
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  )
}
