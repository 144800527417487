import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'
import { getAuth } from 'firebase/auth'

const firebaseConfig = process.env.NODE_ENV === 'production'
  ? {
    apiKey: 'AIzaSyAPH25jTX1QljkRlsrJR39dkJNNAQZtSy8',
    authDomain: 'sokolink-4d025.firebaseapp.com',
    projectId: 'sokolink-4d025',
    storageBucket: 'sokolink-4d025.appspot.com',
    messagingSenderId: '885188418794',
    appId: '1:885188418794:web:315baa1da1c4d7398ecb93',
  }
  : {
    apiKey: 'AIzaSyDc0_JQpna_dS2O6yAPQgQZvJog09j54RM',
    authDomain: 'sokolink-playground.firebaseapp.com',
    projectId: 'sokolink-playground',
    storageBucket: 'sokolink-playground.appspot.com',
    messagingSenderId: '909015901225',
    appId: '1:909015901225:web:cf23b6e9834df325192838',
    measurementId: 'G-634C58H3ND',
  }

const app = initializeApp(firebaseConfig)

const messaging = getMessaging(app)
const auth = getAuth(app)

export { app, messaging, auth }
