/* eslint-disable camelcase */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/no-unescaped-entities */
import { Save } from '@mui/icons-material'
import {
  Box, Typography, DialogActions, TextField, Button,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import ResponsiveModal from '../layout/ResponsiveModal'
import { UPDATE_MERCHANT } from '../../graphql/merchant'
import Logo from '../../img/whatsapp.jpeg'

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5000/api' : 'https://sokolink-api.herokuapp.com/api'

function Actions({ loading, close }) {
  return (
    <DialogActions sx={{
      display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
    }}
    >
      <Button onClick={close} sx={{ mr: 2 }}>Cancel</Button>
      <Button
        type="submit"
        disabled={loading}
        startIcon={<Save />}
        variant="contained"
        form="save-waba-integration-form"
      >
        {loading ? 'Saving...' : 'Save'}
      </Button>
    </DialogActions>
  )
}
function WabaConfigurations({ merchant, update, close }) {
  const [metaAppId, setMetaAppId] = useState(merchant?.metaAppId || '')
  const [wabaBusinessId, setBusinessId] = useState(merchant?.wabaBusinessId || '')
  const [wabaPhoneId, setPhoneId] = useState(merchant?.wabaPhoneId || '')
  const [wabaToken, setToken] = useState(merchant?.wabaToken || '')

  const [updateReq, { loading }] = useMutation(UPDATE_MERCHANT, {
    onError: () => { toast.error('Error updating whatsapp configuration.') },
    onCompleted: (data) => {
      update(data.updateMerchantDetails)
      toast.success('Whatsapp configuration  updated successfully.')
      close()
    },
  })

  const onSave = (e) => {
    e.preventDefault()
    updateReq({
      variables: {
        metaAppId,
        wabaBusinessId,
        wabaPhoneId,
        wabaToken: wabaToken === merchant?.wabaToken ? undefined : wabaToken,
      },
    })
  }
  return (
    <ResponsiveModal
      title="Whatsapp API Configuration"
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions loading={loading} close={close} />}
    >
      <Box component="form" id="save-waba-integration-form" onSubmit={onSave}>
        <TextField fullWidth type="number" label="App Id" value={metaAppId} size="small" required={!!wabaPhoneId || !!wabaBusinessId} onChange={(e) => { setMetaAppId(e.target.value) }} />
        <TextField fullWidth sx={{ mt: 2 }} type="number" label="Business Id" value={wabaBusinessId} size="small" required={!!wabaPhoneId || !!metaAppId} onChange={(e) => { setBusinessId(e.target.value) }} />
        <TextField fullWidth sx={{ mt: 2 }} type="number" label="Phone Number Id" value={wabaPhoneId} size="small" required={!!wabaBusinessId || !metaAppId} onChange={(e) => { setPhoneId(e.target.value) }} />
        <TextField fullWidth sx={{ mt: 2 }} type="password" label="Access Token" value={wabaToken} size="small" onChange={(e) => { setToken(e.target.value) }} />
      </Box>
    </ResponsiveModal>
  )
}

function WhatsappIntegration({ merchant, updateMerchant, firebaseUser }) {
  const [configOpen, toggleConfig] = useState(false)
  const {
    wabaBusinessId, wabaPhoneId, wabaToken,
  } = merchant
  const isConnected = !!wabaBusinessId && !!wabaPhoneId && !!wabaToken
  const phoneNumberIdRef = useRef('')
  const businessAccountIdRef = useRef('')
  const [loading, setLoading] = useState(false)

  const onSuccess = async (code) => {
    try {
      setLoading(true)
      const token = await firebaseUser?.getIdToken()
      // Send request to the server
      const url = `${baseUrl}/waba/onboard`
      const headers = {
        AuthToken: token,
        'Content-Type': 'application/json',
      }

      console.log({
        phoneNumberId: phoneNumberIdRef.current,
        businessAccountId: businessAccountIdRef.current,
        code,
      })

      const options = {
        method: 'POST',
        headers,
        body: JSON.stringify({ phoneNumberId: phoneNumberIdRef.current, businessAccountId: businessAccountIdRef.current, code }),
      }

      const response = await fetch(url, options)
      if (!response.ok) {
        throw new Error('Failed to connect whatsapp.')
      }

      const res = await response.json()
      console.log(res)
      toast.success('Whatsapp connected successfully')
      setLoading(false)
      window.location.reload()
    } catch (error) {
      setLoading(false)
      toast.error(`Error connecting whatsapp: ${error.message}`)
    }
  }

  const disconnect = async () => {
    toast.error('Contact support to disconnect Whatsapp integration.')
    // try {
    //   setLoading(true)
    //   const token = await firebaseUser?.getIdToken()
    //   // Send request to the server
    //   const url = `${baseUrl}/waba/onboard`
    //   const headers = {
    //     AuthToken: token,
    //     'Content-Type': 'application/json',
    //   }

    //   const options = {
    //     method: 'DELETE',
    //     headers,
    //   }

    //   const response = await fetch(url, options)
    //   if (!response.ok) {
    //     throw new Error('Failed to disconnect whatsapp')
    //   }

    //   const res = await response.json()
    //   console.log(res)
    //   toast.success('Whatsapp disconnected successfully')
    //   setLoading(false)
    //   window.location.reload()
    // } catch (error) {
    //   setLoading(false)
    //   toast.error(`Error disconnecting whatsapp: ${error.message}`)
    // }
  }

  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      const { code: loginCode } = response.authResponse
      if (loginCode) {
        onSuccess(loginCode)
      }
      // The returned code must be transmitted to your backend first and then
      // perform a server-to-server call from there to our servers for an access token.
    }
  }

  // eslint-disable-next-line no-unused-vars
  const launchWhatsAppSignup = () => {
    // Launch Facebook login
    // eslint-disable-next-line no-undef
    FB.login(fbLoginCallback, {
      config_id: '2019419871808348', // configuration ID goes here
      response_type: 'code', // must be set to 'code' for System User access token
      override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
      extras: {
        setup: {},
        featureType: 'whatsapp_business_app_onboarding',
        sessionInfoVersion: '2',
      },
    })
  }

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.origin !== 'https://www.facebook.com' && event.origin !== 'https://web.facebook.com') {
        return
      }
      try {
        const data = JSON.parse(event.data)
        console.log(data.type)
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          // if user finishes the Embedded Signup flow
          if (data.event === 'FINISH') {
            const { phone_number_id, waba_id } = data.data
            console.log('Phone number ID ', phone_number_id, ' WhatsApp business account ID ', waba_id)
            phoneNumberIdRef.current = phone_number_id
            businessAccountIdRef.current = waba_id
            // if user cancels the Embedded Signup flow
          } else if (data.event === 'FINISH_WHATSAPP_BUSINESS_APP_ONBOARDING') {
            const { phone_number_id, waba_id } = data.data
            console.log('Phone number ID ', phone_number_id, ' WhatsApp business account ID ', waba_id)
            phoneNumberIdRef.current = phone_number_id
            businessAccountIdRef.current = waba_id
          } else if (data.event === 'CANCEL') {
            const { current_step } = data.data
            console.warn('Cancel at ', current_step)
            toast.error('User cancelled')
            // if user reports an error during the Embedded Signup flow
          } else if (data.event === 'ERROR') {
            const { error_message } = data.data
            console.error('error ', error_message)
            toast.error('Error')
          }
        }
        document.getElementById('session-info-response').textContent = JSON.stringify(data, null, 2)
      } catch {
        console.log('Non JSON Responses', event.data)
      }
    })
    return window.removeEventListener('message', () => {})
  }, [])

  return (
    <Box sx={{
      background: 'linear-gradient(90deg, #020024 0%, #4c0979 10%, #4c0979 100%)',
      width: '500px',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 5,
    }}
    >
      { configOpen ? <WabaConfigurations merchant={merchant} close={() => { toggleConfig(false) }} update={updateMerchant} /> : null }
      <Box sx={{
        display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', py: 2,
      }}
      >
        <Box sx={{ width: '200px', height: '50px', mx: 2 }}>
          <img src={Logo} alt="leta logo" style={{ width: '100%', height: '100%' }} />
        </Box>
        <Box>
          <Typography variant="h5" color="#fff">WhatsApp Business API</Typography>
          <Typography variant="caption" color="#fff">Integrating with WhatsApp Business API offers your businesses a convenient and effective way to send messages programmatically from your own number, enabling you to improve customer engagement, streamline communication workflows, and enhance your brand presence on one of the world's leading messaging platforms.</Typography>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex', justifyContent: 'space-around', alignItems: 'center', py: 2,
      }}
      >
        <Box sx={{
          backgroundColor: '#fff', display: 'flex', alignItems: 'center', p: 1, borderRadius: 5,
        }}
        >
          <Box
            component="span"
            sx={{
              display: 'block',
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: isConnected ? 'success.main' : 'error.main',
              mr: 1,
            }}
          />
          <Typography>{isConnected ? 'Connected' : 'Not Connected'}</Typography>
        </Box>
        {
          !isConnected ? (
            <Button variant="outlined" onClick={launchWhatsAppSignup} disabled={loading}>
              { loading ? 'Connecting...' : 'Connect' }
            </Button>
          ) : (
            <Button variant="outlined" onClick={disconnect} disabled={loading}>
              { loading ? 'Disconnecting...' : 'Disconnect' }
            </Button>
          )
        }

      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  firebaseUser: state.user.user,
})
export default connect(mapStateToProps, { })(WhatsappIntegration)
