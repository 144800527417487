/* eslint-disable no-unused-vars */
import { Download } from '@mui/icons-material'
import {
  Badge,
  Box,
  Button, DialogActions, Typography,
} from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import * as htmlToImage from 'html-to-image'
import download from 'downloadjs'
import QRCodeStyling from 'qr-code-styling'
import ResponsiveModal from '../layout/ResponsiveModal'
import Logo from '../../img/logo.png'

function QR({ data, primaryColor }) {
  const qrCode = new QRCodeStyling({
    width: 300,
    height: 300,
    dotsOptions: {
      color: primaryColor || '#4267b2',
      type: 'extra-rounded',
    },
    imageOptions: {
      crossOrigin: 'anonymous',
    },
  })
  const ref = useRef(null)
  useEffect(() => {
    qrCode.append(ref.current)
  }, [])
  useEffect(() => {
    qrCode.update({
      data,
    })
  }, [])
  return <div ref={ref} />
}

function Actions({ onDownload, close, loading }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        onClick={onDownload}
        startIcon={<Download />}
        variant="contained"
        sx={{ mr: 2 }}
      >
        {loading ? 'Downloading...' : 'Download'}
      </Button>
    </DialogActions>
  )
}

function DownloadQrBanner({
  table, close, merchant, storeNo,
}) {
  const { id, storeId, name } = table
  const { subdomain, primaryColor, secondaryColor } = merchant
  const link = storeId ? `https://${subdomain}.sokolink.com/store/${storeNo}/order?t=${id}` : `https://${subdomain}.sokolink.com?t=${id}`

  const downloadCoupon = () => {
    const node = document.getElementById('instoreqr-holder')
    htmlToImage.toPng(node)
      .then((dataUrl) => {
        download(dataUrl, name)
      })
      .catch(() => {
        // console.error('oops, something went wrong!', error)
      })
  }

  const shapeStyles = { bgcolor: '#000', width: 5, height: 5 }
  const shapeCircleStyles = { borderRadius: '50%' }
  const circle = (<Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles }} />)

  return (
    <ResponsiveModal
      visible
      cancellable
      cancel={close}
      title="Download Qr Code"
      dialogActions={<Actions onDownload={downloadCoupon} close={close} />}
    >
      <style>
        {
          `
          .cont{
            min-width: 500px;
            background:  ${primaryColor};
          }
          `
        }
      </style>
      <div className="cont" id="instoreqr-holder">
        <Box sx={{
          bgcolor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '95%', width: '95%', borderRadius: 1,
        }}
        >
          <Box sx={{
            display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: primaryColor, width: '80%', p: 0.5, my: 1.5, borderRadius: 2,
          }}
          >
            <Typography fontWeight="bold" sx={{ color: '#fff' }}>{`${name}`}</Typography>
          </Box>
          <Box sx={{
            mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <Typography fontWeight="bold">SCAN</Typography>
            <Badge sx={{ mx: 1 }}>{circle}</Badge>
            <Typography fontWeight="bold">ORDER</Typography>
            <Badge sx={{ mx: 1 }}>{circle}</Badge>
            <Typography fontWeight="bold">PAY</Typography>
          </Box>
          <div className="scan">
            <QR data={link} />
          </div>
          <Typography sx={{ mt: 1 }}>Scan the QR code to place your order.</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Typography>Powered By:</Typography>
            <img src={Logo} alt="logo" style={{ height: '30px', width: '100px' }} />
          </Box>
        </Box>
      </div>
    </ResponsiveModal>

  )
}

const mapStateToProps = (state) => ({ merchant: state.merchant.merchantInfo })

export default connect(mapStateToProps, {})(DownloadQrBanner)
