import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { Edit, QrCode, Settings } from '@mui/icons-material'
import { IconButton, Switch, Tooltip } from '@mui/material'
import { useMutation } from '@apollo/client'
import './styles/StoresView.css'
import SaveStoreSettings from './SaveStoreSettings'
import { UPDATE_STORE } from '../../graphql/locations'
import QrControlsDialog from './QrControlsDialog'
import SaveStoreDialog from './SaveStore'

function Location({ location: serverData }) {
  const [location, setLocation] = useState(serverData)
  const {
    id, storeNo, name, details, phone, active,
  } = location
  const [editOpen, toggleEdit] = useState(false)
  const [settingsOpen, toggleSettings] = useState(false)
  const [qrsOpen, toggleQrs] = useState(false)
  const [status, setStatus] = useState(active)

  const [updateReq, { loading }] = useMutation(UPDATE_STORE, {
    onCompleted: () => { setStatus(!status) },
    onError: () => { toast.error('Error updating store status.') },
  })

  const handleToggle = () => {
    updateReq({
      variables: {
        id,
        active: !status,
      },
    })
  }
  return (
    <>
      { editOpen ? <SaveStoreDialog store={location} update={(d) => { setLocation(d) }} close={() => (toggleEdit(false))} /> : null }
      { settingsOpen ? <SaveStoreSettings store={location} update={(d) => { setLocation(d) }} close={() => { toggleSettings(false) }} /> : null }
      { qrsOpen ? <QrControlsDialog storeId={id} storeNo={storeNo} close={() => { toggleQrs(false) }} /> : null }
      <tr className="store">
        <td>
          <p>{storeNo}</p>
        </td>
        <td>
          <p>{name}</p>
        </td>
        <td>
          <p>{details}</p>
        </td>
        <td>
          <p>{phone}</p>
        </td>
        <td>
          <Switch checked={status} disabled={loading} onChange={handleToggle} />
        </td>
        <td>
          <IconButton sx={{ mr: 1.5 }} onClick={() => (toggleEdit(true))}>
            <Edit />
          </IconButton>
          <Tooltip title="store controls" placement="bottom">
            <IconButton sx={{ mr: 1.5 }} onClick={() => { toggleSettings(true) }}>
              <Settings />
            </IconButton>
          </Tooltip>
          <Tooltip title="qr controls" placement="bottom">
            <IconButton sx={{ mr: 1.5 }} onClick={() => { toggleQrs(true) }}>
              <QrCode />
            </IconButton>
          </Tooltip>
        </td>
      </tr>
    </>
  )
}

export default function LocationsView({ visible, locations }) {
  if (!visible) {
    return null
  }
  return (
    <div className="table-container">
      <table className="locations-table">
        <thead>
          <tr>
            <th className="phone-hd">Store No</th>
            <th className="name-hd">Name</th>
            <th className="location-hd">Details</th>
            <th className="phone-hd">Phone</th>
            <th className="phone-hd">Status</th>
            <th className="actions-hd">Actions</th>
          </tr>
        </thead>
        <tbody>
          {locations.map((location) => <Location key={location.id} location={location} />)}
        </tbody>
      </table>
    </div>
  )
}
