import {
  AUTHENTICATE_USER,
  SET_USER_INFO,
  LOGOUT_USER,
} from './types'
import client from '../apollo'
import { auth } from '../services/firebase'
import { deleteToken } from '../services/firebasepush'

export const authenticateUser = (user) => (dispatch) => {
  dispatch({ type: AUTHENTICATE_USER, payload: user })
}
export const setUserInfo = (info) => (dispatch) => {
  dispatch({ type: SET_USER_INFO, payload: info })
}
export const logoutUser = () => async (dispatch) => {
  deleteToken().then(() => {
  }).catch(() => {
  })
  await client.resetStore()
  auth.signOut()
    .then(() => {
      dispatch({ type: LOGOUT_USER })
      window.location.reload()
    })
    .catch(() => {})
}
