import { Save } from '@mui/icons-material'
import {
  Box, Button, DialogActions, TextField,
} from '@mui/material'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { connect } from 'react-redux'
import ResponsiveModal from '../layout/ResponsiveModal'

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5000/api' : 'https://sokolink-api.herokuapp.com/api'

function Actions({
  close, loading,
}) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>Cancel</Button>
      <Button
        type="submit"
        form="change-template-form"
        disabled={loading}
        startIcon={<Save />}
        variant="contained"
        sx={{ mr: 2 }}
      >
        {loading ? 'Saving...' : 'Save'}
      </Button>

    </DialogActions>
  )
}

function ChangeAutomationDialog({
  close, id, name: oldName, onUpdate, firebaseUser,
}) {
  const [name, setName] = useState(oldName || '')
  const [loading, setLoading] = useState(false)

  const onSave = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const token = await firebaseUser?.getIdToken()
      // Send request to the server
      const url = `${baseUrl}/waba/automations/${id}`
      const headers = {
        AuthToken: token,
        'Content-Type': 'application/json',
      }

      const options = {
        method: 'PUT',
        headers,
        body: JSON.stringify({ name }),
      }

      const response = await fetch(url, options)
      if (!response.ok) {
        throw new Error('Failed to update automation')
      }

      const res = await response.json()
      const newTemplate = res.data
      onUpdate(newTemplate)
      close()
      toast.success('Automation updated successfully')
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(`Error updating automation: ${error.message}`)
    }
  }
  return (
    <ResponsiveModal
      title="Change Template"
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions loading={loading} close={close} />}
    >
      <Box component="form" id="change-template-form" onSubmit={onSave}>
        <TextField
          label="Template Name"
          size="small"
          required
          value={name}
          onChange={(e) => {
            const value = e.target.value?.trim().replace(/\s+/g, '_') // Remove leading/trailing spaces and replace spaces with underscores
            const newName = value && value.startsWith('sokolink_') ? `${value}` : 'sokolink_' // Add 'sokolink_' only if there's a value, otherwise empty string
            setName(newName)
          }}
          fullWidth
        />
      </Box>
    </ResponsiveModal>
  )
}

const mapStateToProps = (state) => ({
  firebaseUser: state.user.user,
})
export default connect(mapStateToProps, { })(ChangeAutomationDialog)
