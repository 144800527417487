/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import Smartlook from 'smartlook-client'
import { setUserInfo } from '../actions/userActions'
import { requestFirebaseNotificationPermission } from '../services/firebasepush'
import { APP_INIT } from '../graphql/auth'
import { setMerchant } from '../actions/merchantActions'
import ErrorLoading from '../components/layout/ErrorLoading'
import AppLoading from '../components/spinners/AppLoading'
import AppContainer from '../components/layout/AppContainer'

function PrivateRoute({
  component: Component, firebaseUser, userInfo, merchantInfo,
  setUserInfo: setUserInfoAction, setMerchant: setMerchantAction, ...rest
}) {
  const [error, setError] = useState(null)
  const [appLoading, setAppLoading] = useState(true)
  const [initApp, { loading }] = useLazyQuery(APP_INIT, {
    onError: () => { setAppLoading(false); setError(true) },
    onCompleted: (data) => {
      const { user, merchant } = data
      setUserInfoAction(user)
      setMerchantAction(merchant)
      setAppLoading(false)
    },
  })

  useEffect(() => {
    initApp()
    if (process.env.NODE_ENV === 'production') {
      Smartlook.init('46bff9d24edd8db8c3c4bee79b26e01ed7214175')
    }
  }, [])
  if (loading || appLoading) return <AppLoading />
  if (error) {
    return <ErrorLoading visible fullHeight reload={() => { window.location.reload() }} />
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!firebaseUser) {
          return <Redirect to="/login" />
        }
        if (!userInfo || !merchantInfo) {
          return <ErrorLoading visible fullHeight reload={() => { window.location.reload() }} />
        }
        requestFirebaseNotificationPermission()
          .then((firebaseToken) => {
            localStorage.setItem('firebaseToken', firebaseToken)
          })
          .catch((err) => err)
        return (
          <AppContainer>
            <Component {...props} />
          </AppContainer>
        )
      }}
    />
  )
}
const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
  firebaseUser: state.user.user,
  merchantInfo: state.merchant.merchantInfo,
})
export default connect(mapStateToProps, { setUserInfo, setMerchant })(PrivateRoute)
