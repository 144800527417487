/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { toast } from 'react-hot-toast'
import { Box, Button, TextField } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  EmailAuthProvider, reauthenticateWithCredential, updatePassword,
} from 'firebase/auth'
import ErrorLoading from '../components/layout/ErrorLoading'
import CenterLoader from '../components/spinners/CenterLoader'
import { USER, UPDATE_USER } from '../graphql/auth'
import { auth } from '../services/firebase'

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required('Please enter your current password'),
  newPassword: yup
    .string()
    .required('Please enter a new password')
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must contain at least 8 characters, one uppercase, one number and one special case character',
    ),
})

function UserView({ user }) {
  const { email, name: userName } = user
  const [name, setName] = useState(userName)
  const [updatingPwd, setUpdatingPwd] = useState(false)

  const initialValues = {
    password: '',
    newPassword: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
  })
  const passwordProps = formik.getFieldProps('password')
  const newPasswordProps = formik.getFieldProps('newPassword')

  const handleErrors = () => {
    toast.error('error updating user details')
  }

  const handleResponse = () => {
    toast.success('user updated')
  }

  const [updateUserReq, { loading: updatingUser }] = useMutation(UPDATE_USER, { onError: handleErrors, onCompleted: handleResponse })

  const updateUser = () => {
    updateUserReq({
      variables: {
        name,
      },
    })
  }
  const updatePwd = async () => {
    const firebaseUser = auth.currentUser

    if (!firebaseUser) {
      toast.error('No authenticated user found')
      return
    }

    const credential = EmailAuthProvider.credential(
      firebaseUser.email,
      formik.values.password,
    )

    try {
      setUpdatingPwd(true)
      await reauthenticateWithCredential(firebaseUser, credential)
      await updatePassword(firebaseUser, formik.values.newPassword)
      setUpdatingPwd(false)
      toast.success('Password updated successfully')
    } catch (error) {
      setUpdatingPwd(false)
      if (error.code === 'auth/wrong-password') {
        toast.error('Incorrect password')
      } else {
        toast.error('Error updating password')
      }
    }
  }
  return (
    <div>
      <div className="settings-section">
        <div className="settings-section-header">
          <h3>Personal Information</h3>
          <Button title="Save Changes" onClick={updateUser} disabled={updatingUser}>Save Changes </Button>
        </div>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
          <TextField disabled value={email} id="outlined-basic" label="Email" variant="outlined" sx={{ flex: 0.45, marginTop: 4, marginBottom: 4 }} />
          <TextField value={name} id="outlined-basic" label="Name" variant="outlined" sx={{ flex: 0.45, marginTop: 4, marginBottom: 4 }} onChange={(e) => { setName(e.target.value) }} />
        </Box>
      </div>
      <div className="settings-section">
        <div className="settings-section-header">
          <h3>Password</h3>
          <Button title="Save Changes" onClick={updatePwd} disabled={!formik.isValid || updatingPwd}>Update Password</Button>
        </div>
        <Box
          component="form"
          sx={{
            display: 'flex', justifyContent: 'space-evenly', alignItems: 'center',
          }}
        >
          <TextField type="password" id="outlined-error-helper-text" label="Current Password" variant="outlined" sx={{ flex: 0.45, marginTop: 4, marginBottom: 4 }} {...passwordProps} error={formik.touched.password && formik.errors.password} helperText={formik.errors.password} />
          <TextField type="password" id="outlined-error-helper-text" label="New Password" variant="outlined" sx={{ flex: 0.45, marginTop: 4, marginBottom: 4 }} {...newPasswordProps} error={formik.touched.newPassword && formik.errors.newPassword} helperText={formik.errors.newPassword} />
        </Box>
      </div>
    </div>
  )
}

export default function Account() {
  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)

  const handleResponse = (data) => {
    setError(null)
    setUser(data.user)
  }
  const [getUser, { loading }] = useLazyQuery(USER, {
    fetchPolicy: 'cache-and-network',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })
  const getUserReq = () => {
    getUser({
      variables: {
      },
    })
  }
  useEffect(() => {
    getUserReq()
  }, [])

  return (
    <div className="page-content">
      <CenterLoader visible={loading} />
      <ErrorLoading visible={error && !loading} reload={getUserReq} />
      {!loading && !error && user ? <UserView user={user} /> : null }

    </div>
  )
}
