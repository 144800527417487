import React, { useState } from 'react'
import {
  Button, Popover, List, ListItem, ListItemButton, ListItemText,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

export const predefinedVariables = [
  {
    name: 'customer_name',
    example: 'John',
  },
  {
    name: 'order_no',
    example: 'SKO1234567',
  },
  {
    name: 'service_type',
    example: 'delivery',
  },
  {
    name: 'store_name',
    example: 'Demo Store',
  },
  {
    name: 'store_phone',
    example: '+254702029444',
  },
]

const PredefinedVariableButton = ({ handleAddPredefinedVariable }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'predefined-variable-popover' : undefined

  return (
    <div>
      <Button
        size="small"
        startIcon={<AddIcon />}
        sx={{ textTransform: 'capitalize' }}
        onClick={handleButtonClick}
      >
        Sokolink Variable
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          {predefinedVariables.map((variable) => (
            <ListItem key={variable} disablePadding>
              <ListItemButton
                onClick={() => {
                  handleAddPredefinedVariable(variable)
                  handleClose()
                }}
              >
                <ListItemText primary={variable.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  )
}

export default PredefinedVariableButton
