import { Alert, Box } from '@mui/material'
import WhatsappPageHeader from '../../components/whatsapp/WhatsappPageHeader'
import AutomationTable from '../../components/whatsapp/AutomationTable'

export default function WhatsappAutomationPage() {
  return (
    <Box className="page-content">
      <WhatsappPageHeader activeTab="automation" />
      <Alert severity="info" sx={{ my: 1 }}>Automations help you answer queries from your customers and send automatic notifications when the status of an order changes.</Alert>
      <AutomationTable />
    </Box>
  )
}
