import React, { useEffect, useState } from 'react'
import {
  CheckOutlined, Edit, Error, Warning,
} from '@mui/icons-material'
import {
  Box, CircularProgress, IconButton, Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import { connect } from 'react-redux'
import toast from 'react-hot-toast'
import ErrorLoading from '../layout/ErrorLoading'
import NoData from '../layout/NoData'
import ChangeAutomationDialog from './ChangeAutomationDialog'

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5000/api' : 'https://sokolink-api.herokuapp.com/api'

function Template({ template: defaultTemplate, firebaseUser }) {
  const [template, setTemplate] = useState(defaultTemplate)
  const {
    id, name, label, activeStatus,
  } = template
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [templateStatus, setTemplateStatus] = useState(null)
  const [updateOpen, toggleUpdate] = useState(false)

  const getTemplate = async () => {
    try {
      const token = await firebaseUser?.getIdToken()
      // Send GET request to the server
      const response = await fetch(`${baseUrl}/waba/templates/${name}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          AuthToken: `${token}`,
        },
      })
      if (!response.ok) {
        setError(true)
      }
      const res = await response.json()
      setTemplateStatus(res.data?.template?.status)
    } catch (e) {
      setError(e)
    }
  }

  const handleToggle = async () => {
    if (!id) {
      try {
        setLoading(true)
        const token = await firebaseUser?.getIdToken()
        // Send POST request to the server
        const response = await fetch(`${baseUrl}/waba/automations/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            AuthToken: `${token}`,
          },
          body: JSON.stringify({ template: { ...template, activeStatus: true } }),
        })
        if (!response.ok) {
          throw new Error('Error creating automation.')
        }
        const res = await response.json()
        const newTemplate = res.data
        setTemplate(newTemplate)
        toast.success('Automation created successfully.')
        setLoading(false)
      } catch (e) {
        setLoading(false)
        toast.error('Error creating automation.')
      }
    } else {
      try {
        setLoading(true)
        const token = await firebaseUser?.getIdToken()
        // Send PUT request to the server
        const response = await fetch(`${baseUrl}/waba/automations/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            AuthToken: `${token}`,
          },
          body: JSON.stringify({ activeStatus: !activeStatus }),
        })
        if (!response.ok) {
          throw new Error('Error updating automation.')
        }
        const res = await response.json()
        const newTemplate = res.data
        setTemplate(newTemplate)
        toast.success('Automation updated successfully.')
        setLoading(false)
      } catch (e) {
        setLoading(false)
        toast.error('Error updating automation.')
      }
    }
  }

  useEffect(() => {
    getTemplate()
  }, [template])

  return (
    <Box sx={{
      width: '100%', display: 'flex', alignItems: 'center', px: 1, borderBottom: '1px solid #ddd',
    }}
    >
      { updateOpen ? <ChangeAutomationDialog id={id} name={name} onUpdate={(t) => { setTemplate(t) }} close={() => { toggleUpdate(false) }} /> : null }
      <Box sx={{ flex: 0.45, display: 'flex', alignItems: 'center' }}>
        <Typography>{name}</Typography>
        <Box sx={{ ml: 2 }}>
          { templateStatus === 'APPROVED' ? (
            <Tooltip title="Whatsapp template ready for use.">
              <CheckOutlined sx={{ color: 'green' }} />
            </Tooltip>
          ) : null}
          {templateStatus !== 'APPROVED' && !error ? (
            <Tooltip title={`Template status is ${templateStatus}.`}>
              <Warning sx={{ color: 'orange' }} />
            </Tooltip>
          ) : null }
          {
          error ? (
            <Tooltip title="Can't find whatsapp template for this automation.">
              <Error sx={{ color: 'red' }} />
            </Tooltip>
          ) : null
         }
        </Box>
      </Box>
      <Box sx={{ flex: 0.45 }}>
        <Typography>{label}</Typography>
      </Box>
      <Box sx={{
        flex: 0.1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
      }}
      >
        <Switch checked={activeStatus} disabled={loading} onChange={handleToggle} />
        <Tooltip title="Edit template">
          <IconButton sx={{ mx: 5 }} onClick={() => { toggleUpdate(true) }}>
            <Edit />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )
}

function AutomationTable({ firebaseUser }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [templates, setTemplates] = useState([])

  useEffect(async () => {
    try {
      setLoading(true)
      const token = await firebaseUser?.getIdToken()
      // Send GET request to the server
      const response = await fetch(`${baseUrl}/waba/automations`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          AuthToken: `${token}`,
        },
      })
      if (!response.ok) {
        setLoading(false)
        setError(true)
      }
      const res = await response.json()
      setTemplates(res.data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setError(e)
    }
  }, [])

  if (loading) {
    return (
      <Box sx={{
        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', my: 3,
      }}
      >
        <CircularProgress size={20} />
      </Box>
    )
  }

  if (error) return (<ErrorLoading visible reload={() => { window.location.reload() }} />)

  const updatesTemplates = templates.filter((t) => (t.category === 'order_status'))
  const queriesTemplates = templates.filter((t) => (t.category === 'customer_query'))

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mb: 2 }}>
        <Typography> Order Status Update</Typography>
        <Box sx={{ bgcolor: 'ButtonShadow', border: '1px solid #ddd' }}>
          { updatesTemplates.map((t) => (<Template key={t.autoId} template={t} firebaseUser={firebaseUser} />))}
        </Box>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography>Customer Queries Reply</Typography>
        <Box sx={{ bgcolor: 'ButtonShadow', border: '1px solid #ddd' }}>
          { queriesTemplates.map((t) => (<Template key={t.autoId} template={t} firebaseUser={firebaseUser} />))}
        </Box>
      </Box>
      <NoData visible={!loading && !error && !templates?.length} />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  firebaseUser: state.user.user,
})
export default connect(mapStateToProps, { })(AutomationTable)
